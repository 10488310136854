import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import Sidebar from '../../app/components/AppComponents/Sidebar'
import {reInitMenu} from '../helpers'
import {Modal, Button, Layout, Flex, Alert, message, Steps} from 'antd'
import {useProfileContext} from '../../app/context/profilecontext'
import TeamInfo from '../../app/components/Reusablecomponents/TeamInfo'
import PersonalInfo from '../../app/components/Reusablecomponents/PersonalInfo'
import {SaveUserInformationApi} from '../../app/apicalls/ApiCall'
import {userId} from '../../app/components/DataTypes/UserDataTypes'
import PageLoader from '../../app/components/Subcomponents/PageLoader'
const {Sider, Content} = Layout

const MasterLayout = () => {
  const location = useLocation()
  const {setStartTour, startTour, setSignupComplete, signupComplete} = useProfileContext()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  const [open, setOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [alertVisible, setAlertVisible] = useState(true)
  const [typeOfTeam, setTypeOfTeam] = useState<{type: string}>({type: ''})
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [personalInfo, setPersonalInfo] = useState<{
    designation: string
    mobile: string
    tool: string
    companyName: string
  }>({designation: '', mobile: '', tool: '', companyName: ''})

  const toggleSider = () => {
    setCollapsed(!collapsed)
  }
  let val = false
  useEffect(() => {
    const getInfo = localStorage.getItem('getUserdetails')
    if (getInfo) {
      let showModal = JSON.parse(getInfo)
      setShowGetInfo(showModal)
    }
  }, [])
  useEffect(() => {
    if (signupComplete) {
      const localVal = localStorage.getItem('startTour')
      if (localVal !== null) {
        setOpen(true)
      }
      const storedValue = localStorage.getItem('startTour')
      val = storedValue !== null ? JSON.parse(storedValue) : false
      if (val) {
        setOpen(true)
      }
    }
  }, [signupComplete])

  const step = [
    {
      key: 'teamInfo',
      title: 'Team information',
      content: <TeamInfo typeOfTeam={setTypeOfTeam} teamType={typeOfTeam} />,
    },
    {
      title: 'Personal Information',
      content: <PersonalInfo setPersonalInfo={setPersonalInfo} personalInfo={personalInfo} />,
    },
  ]
  useEffect(() => {
    setIsFormValid(checkFormValidity())
  }, [personalInfo])
  const handlePrev = () => {
    setCurrentStep(currentStep - 1)
  }
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [showGetInfo, setShowGetInfo] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)
  const handleFinish = async () => {
    try {
      setShowLoader(true)
      let newObj = {
        team: typeOfTeam.type,
        designation: personalInfo.designation,
        mobile: personalInfo.mobile,
        tool: personalInfo.tool,
        companyName: personalInfo.companyName,
      }
      const res = await SaveUserInformationApi(userId, newObj)
      setShowLoader(false)
      if (res && res.success && res.data) {
        message.success('Welcome to Beanbag AI')
        localStorage.removeItem('getUserdetails')
        setSignupComplete(true)
        setShowGetInfo(false)
      } else {
        message.error('Error while saving the information')
      }
    } catch (err) {
      message.error('Error while saving the information ' + err)
      console.error('Error while saving the information ', err)
    }
  }
  const handleNext = () => {
    setCurrentStep(currentStep + 1)
  }

  const checkFormValidity = () => {
    return (
      personalInfo.designation.trim() !== '' &&
      personalInfo.mobile.trim() !== '' &&
      personalInfo.tool.trim() !== '' &&
      personalInfo.companyName.trim() !== ''
    )
  }
  const items = step.map((item) => ({key: item.title, title: item.title}))
  return (
    <>
      <Alert
        style={{
          zIndex: '1000',
          width: '100%',
          background: '#b0ebb9',
          color: 'black',
          outline: 'none',
          border: 'none',
          borderRadius: '0',
        }}
        message={
          <Flex justify='center' align='center' gap={'small'}>
            <span>
              Never reach a wrong number again, Prospect Smarter with our Chrome Extension!
            </span>
            <Button
              size='small'
              style={{background: 'black', color: 'white', border: 'none'}}
              onClick={() => {
                window.open('https://bit.ly/3TDjuMX', '_blank')
              }}
            >
              Get Extension
            </Button>
          </Flex>
        }
        closable
        onClose={() => setAlertVisible(false)}
      />
      <Layout style={{height: `calc(100vh - ${alertVisible ? '43px' : '0px'})`}}>
        <Sider
          collapsed={collapsed}
          style={{
            position: 'relative',
            zIndex: '50',
            background: '#f9f9f9',
          }}
          onCollapse={toggleSider}
          collapsedWidth={90}
          width={220}
        >
          <Sidebar toggleSider={toggleSider} collapsed={collapsed} />
        </Sider>
        <Content style={{padding: '15px', overflowY: 'scroll', background: 'white'}}>
          <Modal
            centered
            width={800}
            closable={false}
            open={showGetInfo}
            footer={[
              currentStep === step.length - 1 ? (
                <>
                  <Button key='prev' onClick={handlePrev}>
                    Previous
                  </Button>
                  <Button
                    key='submit'
                    type='primary'
                    onClick={handleFinish}
                    disabled={!isFormValid}
                  >
                    Save Information
                  </Button>
                </>
              ) : (
                <>
                  <Button key='prev' onClick={handlePrev} disabled={currentStep === 0}>
                    Previous
                  </Button>
                  <Button
                    type='primary'
                    key='next'
                    onClick={handleNext}
                    disabled={typeOfTeam.type === ''}
                  >
                    Next
                  </Button>
                </>
              ),
            ]}
          >
            <Flex gap={'middle'} vertical className='p-4'>
              {showLoader && <PageLoader />}
              {step[currentStep].content}
              <Steps size='small' current={currentStep} items={items} />
            </Flex>
          </Modal>
          <Modal
            title={
              <span className='heading fs-4' style={{color: '#204f58'}}>
                Welcome to Beanbag AI
              </span>
            }
            centered
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setStartTour(true)}
            footer={[
              <Button
                key='cancel'
                onClick={() => {
                  setOpen(false)
                  localStorage.removeItem('startTour')
                  setSignupComplete(true)
                }}
              >
                Skip Tour
              </Button>,
              <Button
                key='submit'
                type='primary'
                onClick={() => {
                  setStartTour(true)
                  setOpen(false)
                }}
              >
                Take it away Mr. Bean
              </Button>,
            ]}
          >
            <span>
              Let's get you all comfy with Beanbag! It's a pleasure to walk you through our product
              so you take note of the best features!
            </span>
          </Modal>
          <Outlet />
        </Content>
      </Layout>
    </>
  )
}

export {MasterLayout}
