import React, {useState, useEffect} from 'react'
import {Flex, Input, Select, Divider} from 'antd'

const PersonalInfo = ({setPersonalInfo, personalInfo}) => {
  const [showOthersInput, setShowOthersInput] = useState(false)
  const [designation, setDesignation] = useState('')
  const [mobile, setMobile] = useState('')
  const [tool, setTool] = useState('')
  const [companyName, setCompanyName] = useState('')

  useEffect(() => {
    setDesignation(personalInfo.designation)
    setMobile(personalInfo.mobile)
    setTool(personalInfo.tool)
  }, [])

  const options = [
    {value: 'Lusha', label: 'Lusha'},
    {value: 'Apollo', label: 'Apollo'},
    {value: 'ContactOut', label: 'ContactOut'},
    {value: 'EasyLeadz', label: 'EasyLeadz'},
    {value: 'Others', label: 'Others'},
  ]

  const handleDesignationChange = (value) => {
    setDesignation(value)
    setPersonalInfo({...personalInfo, designation: value})
  }

  const handleCompanyName = (value) => {
    setCompanyName(value)
    setPersonalInfo({...personalInfo, companyName: value})
  }

  const handlePhoneNumberChange = (value) => {
    setMobile(value)
    setPersonalInfo({...personalInfo, mobile: value})
  }

  const handleSelectChange = (value) => {
    if (value === 'Others') {
      setTool('')
      setShowOthersInput(true)
    } else {
      setTool(value)
      setShowOthersInput(false)
      setPersonalInfo({...personalInfo, tool: value})
    }
  }

  const handleOthers = (value) => {
    setTool(value)
    setPersonalInfo({...personalInfo, tool: value})
  }

  return (
    <Flex vertical gap={'small'}>
      <Divider>
        <span>Personal information</span>
      </Divider>
      <Flex align='center' gap={'middle'} justify='space-between' wrap='wrap'>
        <Flex vertical style={{width: '48%'}}>
          <span className='fs-7 text-grey'>Enter your Company name</span>
          <Input
            style={{fontSize: '0.9em', fontFamily: "'Karla',sans-serif"}}
            value={companyName}
            onChange={(e) => handleCompanyName(e.target.value)}
            required
            placeholder='Your company name'
            size='large'
          />
        </Flex>
        <Flex vertical style={{width: '48%'}}>
          <span className='fs-7 text-grey'>What's your designation</span>
          <Input
            style={{fontSize: '0.9em', fontFamily: "'Karla',sans-serif"}}
            value={designation}
            onChange={(e) => handleDesignationChange(e.target.value)}
            required
            placeholder='Your designation'
            size='large'
          />
        </Flex>
        <Flex vertical style={{width: '48%'}}>
          <span className='fs-7 text-grey'>What's your mobile number?</span>
          <Input
            style={{fontSize: '0.9em', fontFamily: "'Karla',sans-serif"}}
            value={mobile}
            onChange={(e) => handlePhoneNumberChange(e.target.value)}
            required
            placeholder='Enter your mobile number'
            size='large'
          />
        </Flex>
        <Flex vertical style={{width: '48%'}}>
          <span className='fs-7 text-grey'>Have you used any similar tool before?</span>
          <Select
            value={tool}
            size='large'
            style={{width: '100%', fontSize: '0.9em', fontFamily: "'Karla',sans-serif"}}
            placeholder='Select your tool'
            options={options}
            onChange={handleSelectChange}
          />
          {showOthersInput && (
            <Input
              value={tool}
              onChange={(e) => handleOthers(e.target.value)}
              size='large'
              required
              placeholder='Enter your tool'
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PersonalInfo
